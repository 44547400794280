import {useCallback, useEffect, useRef, useState} from "react";
import ReactDOM from "react-dom";
import clsx from "clsx";

export function HoverCellInner({ children }) {
    const ref = useRef();
    const elem = useRef(document.createElement('div'));

    useEffect(() => {
        elem.current.classList.add('hover-cell-portal');
        document.body.appendChild(elem.current);

        return () => {
            elem.current.remove();
        };
    }, []);

    const [hover, setHover] = useState(false);;
    const [left, setLeft] = useState(0);
    const [top, setTop] = useState(0);
    const onHover = useCallback(() => {
        setHover(true);
        const bbox = ref.current.getBoundingClientRect();
        const top = bbox.bottom + window.scrollY;
        const left = (bbox.right + bbox.left) / 2 + window.scrollX;

        setLeft(left);
        setTop(top);
    }, []);
    const onBlur = useCallback(() => setHover(false), []);

    return (
        <div className="hover-cell-anchor" onMouseEnter={onHover} onMouseLeave={onBlur} ref={ref}>
            {
                ReactDOM.createPortal(
                    <div className={clsx("hover-cell-disp", { shown: hover })} style={{
                        left, top
                    }}>
                        {children}
                    </div>,
                    elem.current,
                )
            }
        </div>
    );
}
