import {defaultConfig} from "./config";

export default {
    name: 'AIPerf-Inference (Latency) ChinaSC2024 List',
    columns: defaultConfig,
    data: [
        {
            manufacturer: 'Beijing Beilong Super Cloud Computing Co., Ltd.',
            site: 'BEIJING SUPER CLOUD COMPUTING CENTER',
            score: '0.36',
            conf: ['4 nodes, NVIDIA®H100-80GB * 32', 'Intel® Xeon® Platinum 8358 CPU @ 2.60GHz',]
        },
        {
            manufacturer: 'LTHPC',
            site: 'LTHPC',
            score: '0.46',
            conf: ['6 nodes, NVIDIA®H-Series - 80GB * 44', 'Intel® Xeon® Platinum 8468',]
	},
        {
            manufacturer: 'NVIDIA',
            site: 'Renmin University of China',
            score: '0.50',
            conf: ['2 nodes, NVIDIA®H100-80GB * 16', 'AMD EPYC 7402 24C@2.80GHz Processor',]
        },
        {
            manufacturer: 'NVIDIA',
            site: 'Shanghai Jiao Tong University',
            score: '0.52',
            conf: ['4 nodes, NVIDIA®A100-80GB * 32', 'Intel® Xeon® Platinum 8468',]
        },
        {
            manufacturer: 'Tianjin Tier Technology Co.，Ltd.',
            site: 'Tianjin Tier Technology Co.，Ltd.',
            score: '0.53',
            conf: ['1 nodes, NVIDIA®Geforce®RTX-4090-24GB * 10', 'Intel® Xeon® 8473C *2',]
        },
        {
            manufacturer: 'NVIDIA',
            site: 'TsingHua University',
            score: '0.64',
            conf: ['4 nodes, NVIDIA®A800-80GB * 8', 'AMD EPYC 7742 64-Core Processor',]
        },
        {
            manufacturer: 'NVIDIA',
            site: 'HPC+AI Team (ICT)',
            score: '0.69',
            conf: ['1 nodes, NVIDIA®A800-80GB * 4', 'Intel® Xeon® Silver 4210 CPU @ 2.20GHz',]
        },
        {
            manufacturer: 'Tianjin Tier Technology Co.，Ltd.',
            site: 'Tianjin Tier Technology Co.，Ltd.',
            score: '0.75',
            conf: ['1 nodes, NVIDIA®Geforce®RTX-4090-24GB * 1', 'Intel® Xeon® 8473C *2',]
        },
        {
            manufacturer: 'NVIDIA',
            site: 'Xidian University',
            score: '0.77',
            conf: ['1 nodes, NVIDIA®A100-SXM4-40GB * 8', 'Intel® Xeon® Platinum 8358 CPU @ 2.60GHz',]
        },
        {
            manufacturer: 'HUAWEI',
            site: 'A Technology company',
            score: '0.91',
            conf: ['1 nodes, Kunpeng 920 2.6GHz', 'Huawei Ascend910-32GB * 8',]
        },
    ].sort((a, b) => parseFloat(a.score) - parseFloat(b.score)),
}
