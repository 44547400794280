import AIPerf2023 from './2024-AIPerf';
import LLM2023 from './2024-AIPerf-LLM';
import InferenceThroughput from './InferenceThroughput';
import InferenceLatency from './InferenceLatency';

export default [
    AIPerf2023,
    LLM2023,
    InferenceThroughput,
    InferenceLatency,
];
